@font-face {
    font-family: 'Nunito Sans';
    src: url('./Nunito_Sans/webfonts/nunitosans-regular-webfont.woff2') format('woff2'),
         url('./Nunito_Sans/webfonts/nunitosans-regular-webfont.woff') format('woff'),
         url("./Nunito_Sans/NunitoSans-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./Nunito_Sans/webfonts/nunitosans-bold-webfont.woff2') format('woff2'),
         url('./Nunito_Sans/webfonts/nunitosans-bold-webfont.woff') format('woff'),
         url("./Nunito_Sans/NunitoSans-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./Nunito_Sans/webfonts/nunitosans-italic-webfont.woff2') format('woff2'),
         url('./Nunito_Sans/webfonts/nunitosans-italic-webfont.woff') format('woff'),
         url("./Nunito_Sans/NunitoSans-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
}
