
html,
body {
  padding: 0;
  margin: 0;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  height: 100vh;
  overflow: auto;
  height: -webkit-fill-available;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

:root {
  /** New Figma? */
  --visionable-mid-blue: #1271ee;
  --visionable-accent-blue: #ccdff8;

  --visionable-dark-blue: #031c3a;
  --visionable-accent-green: #01b695;
  --visionable-font-beige: #535b5e;

  --visionable-border-gray: #cccccc;
  --visionable-background-gray: #fafafa;

  /** COLORS - Shades */
  --black: #000;
  --light-grey: #ccc;
  --lighter-grey: #fafafa;
  --white: #fff;

  /** COLORS - Primary */
  --dark-blue: #031c3a;
  --blue: #042349;
  --blue-85: #294464;
  --blue-50: #8190a3;
  --blue-35: #a7b2c0;
  --blue-alpha-85: #042349d9;
  --blue-alpha-50: #04234980;
  --blue-alpha-35: #04234959;
  --blue-alpha-10: #0423491a;

  /** COLORS - Secondary */
  --mid-blue: #c1cdd1;
  --mid-blue-light: #78d4f8;
  --mid-blue-85: #26baf1;
  --mid-blue-50: #7fd6f6;
  --mid-blue-35: #e0f5fd;
  --mid-blue-alpha-85: #00aeefd9;
  --mid-blue-alpha-50: #00aeef80;
  --mid-blue-alpha-35: #00aeef59;
  --mid-blue-alpha-10: #00aeef1a;

  /** COLORS - Tertiary */
  --teal: #04c3b3;
  --teal-85: #29ccbe;
  --teal-50: #81e0d8;
  --teal-35: #e0f8f6;
  --teal-alpha-85: #04c3b3d9;
  --teal-alpha-50: #04c3b380;
  --teal-alpha-35: #04c3b359;
  --teal-alpha-10: #04c3b31a;

  --yellow: #fda40e;
  --yellow-85: #fab13f;
  --yellow-50: #fdd186;
  --yellow-35: #fff4e2;
  --yellow-alpha-85: #fda40ed9;
  --yellow-alpha-50: #fda40e80;
  --yellow-alpha-35: #fda40e59;
  --yellow-alpha-10: #fda40e1a;

  --red: #ff0000;
  --red-85: #ff2626;
  --red-50: #ff7f7f;
  --red-35: #ffe0e0;
  --red-alpha-85: #ff0000d9;
  --red-alpha-50: #ff000080;
  --red-alpha-35: #ff000059;
  --red-alpha-10: #ff00001a;

  /** COLORS - Configurator */
  --config-pink: #fd597e;
  --config-purple: #652bb3;
  --config-green: #12cdd4;
  --config-blue: #2d9bf0;

  /** COLORS - Configurator Accents */
  --config-accent-pink: #fd597e;
  --config-accent-orange: #fe6d1b;
  --config-accent-yellow: #fead13;
  --config-accent-lime-green: #ddcd2f;
  --config-accent-olive-green: #919a3d;
  --config-accent-green: #01b695;
  --config-accent-aqua: #00a9ce;
  --config-accent-blue-a: #41b6e6;
  --config-accent-blue-b: #00aeef;
  --config-accent-warm-gray: #a79798;
  --config-accent-cool-gray: #909fa4;

  /** Gradients */
  --blue-50-gradient: linear-gradient(
    to top,
    var(--blue-50) 0%,
    transparent 100%
  );
  --mid-blue-50-gradient: linear-gradient(
    to top,
    var(--mid-blue-50) 0%,
    transparent 100%
  );
  --teal-50-gradient: linear-gradient(
    to top,
    var(--teal-50) 0%,
    transparent 100%
  );
  --yellow-50-gradient: linear-gradient(
    to top,
    var(--yellow-50) 0%,
    transparent 100%
  );
  --red-50-gradient: linear-gradient(
    to top,
    var(--red-50) 0%,
    transparent 100%
  );

  /** Color map */
  --success: var(--config-accent-green);
  --warning: var(--yellow);
  --error: var(--red);
}

/* OVERRIDES CSS */
/* CALENDAR */
.css-1dozdou {
  display: none !important;
}
.css-dhopo2 {
  min-height: 230px;
}
/* AMPLIFY LOGIN */
.amplify-tabs {
  display: none !important;
}
/* Amplify */
[data-amplify-authenticator] {
  --amplify-components-button-primary-color: white;
  --amplify-components-button-primary-background-color: #1271ee;
  --amplify-components-button-primary-hover-background-color: #0f59ba;
  --amplify-components-button-primary-focus-background-color: #cccccc;
  --amplify-components-button-primary-active-background-color: #3877ca;

  --amplify-components-authenticator-router-border-width: 0;
  --amplify-components-authenticator-router-box-shadow: none;
  --amplify-components-authenticator-container-width-max: 400px;
  --amplify-components-fieldset-gap: 50px;
}

[data-amplify-authenticator] [data-amplify-footer] {
  text-align: left !important;
}
.amplify-button--small {
  display: none !important;
}

.amplify-field-group__outer-end {
  border-width: 1px !important;
  border-inline-start-color: lightgray !important;
  border-color: lightgray !important;
}
.amplify-field-group__outer-end:hover {
  background: #e4edfa !important;
  border-width: 1.5px !important;
  border-color: lightgray !important;
}
.amplify-field-group__outer-end:hover :active {
  background: #e4edfa !important;
  border-width: 1.5px !important;
}
.amplify-field-group__outer-end :hover {
  background: #e4edfa !important;
  border-color: #1271ee !important;
  border-width: 1px !important;
  border-left-width: 0.1px !important;
}
.amplify-field-group__outer-end :focus {
  background: #e4edfa !important;
  border-width: 1.5px !important;
  border-color: #1271ee !important;
}
.amplify-input {
  border-color: lightgray !important;
}
.amplify-input:focus {
  border-color: #1271ee !important;
  border-width: 1px;
}
.amplify-field-group__outer-end .amplify-field-group__control:not(:focus) {
  border: 1px lightgray solid;
}
.amplify-icon > svg {
  color: gray;
}

/* Phone number input label overide */
.special-label {
  display: none !important;
}

/* Chat Messages */
.im-message.mine + .im-message:not(.mine),
.im-message:not(.mine) + .im-message.mine {
  margin-top: .8em;
}
.message-scroller {
  display: flex;
  flex-direction: column;
}
